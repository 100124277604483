<template>
    <div class="wraper">
        <Header :isShow="isShow" />
        <!-- 轮播图 -->
        <div class="banner" >
            <img src="@/assets/images/industy.png" alt="" />
        </div>
        <!-- 电脑端滑块 -->
        <div class="slider_box" ref="header">
            <div class="slider max_big_box">
                <div class="swiper-container max_small_box">
                    <div class="swiper-wrapper">
                        <div :class="sliderActive == index ? 'swiper-slide swiper-slide_active' : 'swiper-slide' " v-for="(item, index) in sliderList" :key="index" @click="handleSliderIndex(index)">
                            <img class="list_img" src="@/assets/images/slider_one.png" alt="">
                            <p class="list_title">{{item.name}}</p>
                            <div class="right_line"></div>
                        </div>
                    </div>
                    <!-- Add Arrows -->
                </div>
                <div class="swiper-button-next swiper-button-black"></div>
                <div class="swiper-button-prev swiper-button-black"></div>
            </div>
        </div>
        <!-- 手机端滑块 -->
            <div class="slider_box_two" >
                <div class="slider max_big_box">
                    <div class="swiper-container_two max_small_box">
                        <div class="swiper-wrapper">
                            <div :class="sliderActive == index ? 'swiper-slide swiper-slide_active' : 'swiper-slide' " v-for="(item, index) in sliderList" :key="index" @click="handleSliderIndex(index)">
                                <img class="list_img" src="@/assets/images/slider_one.png" alt="">
                                <p class="list_title">{{item.name}}</p>
                                <div class="right_line"></div>
                            </div>
                        </div>
                        <!-- Add Arrows -->
                    </div>
                </div>
            </div>
            <!-- 内容 -->
            <div class="content_box">
                <div class="content max_big_box">
                    <div class="img">
                        <img src="@/assets/images/news.png" />
                    </div>
                    <div class="text">
                        <p class="title">食品</p>
                        <p class="desc">中心拥有的服装省站、毛织省站承担国家指定的纺织服装产品质量监督抽查，定期监督检验，名牌、免检产品的评价鉴定检验；纺织服装产品CQC认证的申请、检测工作；纺织服装产品质量仲裁和质量申诉检验。并从事纤维制品的质量检验与认证工作，受理“生态纺织品标志”、“纯天然纤维含量标志”等业务的申请、审核、检验、证书和标志的发放。为国内外客户提供纺织服装产品质量检验服务、技术咨询及技术服务，并为其出具独立的第三方检测报告</p>
                        <div class="btns">
                            <div class="first">咨询留言</div>
                            <div class="second">选择行业</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 列表 -->
            <div class="list_content_box">
                <div class="list_content max_small_box">
                    <div class="list">
                        <p class="title">服务介绍</p>
                        <p class="line"></p>
                        <p class="desc">中心拥有的四个国家级检验中心、十三个省级质检站可为产品质量评价、成果鉴定等提供公正数据，中心通过中国合格评定国家认可委员会(CNAS)的认可所出具的检测证书得到国际上45个经济体的54个实验室认可机构及大多数发达国家政府机构的广泛承认。</p>
                    </div>
                    <div class="list">
                        <p class="title">服务介绍</p>
                        <p class="line"></p>
                        <p class="desc">中心拥有的四个国家级检验中心、十三个省级质检站可为产品质量评价、成果鉴定等提供公正数据，中心通过中国合格评定国家认可委员会(CNAS)的认可所出具的检测证书得到国际上45个经济体的54个实验室认可机构及大多数发达国家政府机构的广泛承认。</p>
                    </div>
                    <div class="list">
                        <p class="title">服务介绍</p>
                        <p class="line"></p>
                        <p class="desc">中心拥有的四个国家级检验中心、十三个省级质检站可为产品质量评价、成果鉴定等提供公正数据，中心通过中国合格评定国家认可委员会(CNAS)的认可所出具的检测证书得到国际上45个经济体的54个实验室认可机构及大多数发达国家政府机构的广泛承认。</p>
                    </div>
                </div>
            </div>
            <div class="bottom_line_box">
                <div class="bottom_line max_big_box"></div>
            </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import Swiper, {   
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  HashNavigation,
  History,
  Keyboard,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom, } from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//滑块处理函数
const sliderEffect = () => {
    //选中的index
    const sliderActive = ref(0)
    // 数据列表
    const sliderList = reactive([
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
        {name: '服装'},
    ])

    //轮播电脑端
    const silderSwiper = () => {
        new Swiper('.swiper-container', {
            slidesPerView: 6,
            spaceBetween: 0,
            slidesPerGroup: 6,
            loop: true,
            loopFillGroupWithBlank: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    //轮播手机端
    const silderSwiperTwo = () => {
        new Swiper('.swiper-container_two', {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerGroup: 3,
            loop: true,
            loopFillGroupWithBlank: true,
        });
    }

    const handleSliderIndex = (index) => {
        sliderActive.value = index
    }

    return { sliderList, silderSwiper, silderSwiperTwo, sliderActive, handleSliderIndex }
}

export default {
    name: 'Service',
    components: { Header, Footer, FloatingFrame }, 
    setup() {

        //滑块处理函数
        const { 
            sliderList, 
            silderSwiper, 
            silderSwiperTwo,
            sliderActive, 
            handleSliderIndex 
        } = sliderEffect()

        onBeforeMount(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#F8F8F8')
        })

        onMounted(() => {
            silderSwiper()
            silderSwiperTwo()
            // console.dir(header.value)
            // // console.log(header.value.offsetTop)
            window.addEventListener('scroll', function () {
                // handleScroll(header.value.offsetTop);
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        return { sliderList, sliderActive, handleSliderIndex }
    }
}
</script>

<style lang="scss" scoped>
/* 轮播图 */
.banner{
    margin-top: 114px;
    @media screen and (max-width: 1200px){
        margin-top: 92px;
    }
    img{
        width: 100%;
        display: block;
    }
}
/* 电脑端滑块 */
.slider_box{
    background: #fff;
    @media screen and (max-width: 1200px){
        display: none;
    }
    .slider{
        position: relative;
        .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-wrapper{
                .swiper-slide {
                    /* width: 165px; */
                    height: 165px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    /* border-right: 1px solid#bbbbbb70;
                    border-left: 1px solid#bbbbbb70; */
                    cursor: pointer;
                    transition: .3s all;
                    .list_img{
                        width: 80px;
                        height: 80px;
                        object-fit: scale-down;
                    }
                    .list_title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                    }
                    .right_line{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 1px;
                        background: #bbbbbb70;
                    }
                }
                .swiper-slide_active{
                    background: #2F509E;
                    .list_title{
                        color: #fff;
                    }
                }
            }
        }
        .swiper-button-next{
            right: 0px;
            left: auto;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            background: #fff;
            
        }
        .swiper-button-black{
            --swiper-navigation-color:  rgba(119, 119, 119, 100);;
        }
        .swiper-button-prev{
            right: auto;
            left: 00px;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            background: #fff;
        }
    }
}
/* 手机端滑块 */
.slider_box_two{
    background: #fff;
    display: none;
    @media screen and (max-width: 1200px){
        display: block;
    }
    .slider{
        position: relative;
        .swiper-container_two {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .swiper-wrapper{
                .swiper-slide {
                    /* width: 165px; */
                    height: 165px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    /* border-right: 1px solid#bbbbbb70;
                    border-left: 1px solid#bbbbbb70; */
                    cursor: pointer;
                    transition: .3s all;
                    position: relative;
                    .list_img{
                        width: 80px;
                        height: 80px;
                        object-fit: scale-down;
                    }
                    .list_title{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                    }
                    .right_line{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 1px;
                        background: #bbbbbb70;
                    }
                }
                .swiper-slide_active{
                    background: #2F509E;
                    .list_title{
                        color: #fff;
                    }
                }
            }
        }
        .swiper-button-next{
            right: 0px;
            left: auto;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            background: #fff;
            
        }
        .swiper-button-black{
            --swiper-navigation-color:  rgba(119, 119, 119, 100);;
        }
        .swiper-button-prev{
            right: auto;
            left: 00px;
            top: 22px;
            bottom: 0;
            height: 100%;
            padding: 0 15px;
            background: #fff;
        }
    }
}
/* 内容 */
.content_box{
    margin-top: 20px;
    background: #fff;
    .content{
        display: flex;
        @media screen and (max-width: 1200px){
            display: block;
        }
        .img{
            width: 30%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        .text{
            width: 60%;
            padding: 0 10%;
            @media screen and (max-width: 1200px){
                width: auto;
                padding: 30px 30px;
            }
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                margin-top: 18px;
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 30px;
                line-height: 24px;
            }
            .btns{
                display: flex;
                align-items: center;
                margin-top: 20px;
                .first{
                    background: #2F509E;
                    font-size: 16px;
                    color: #fff;
                    padding: 10px 15px;
                    border-radius: 20px;
                    text-align: center;
                    cursor: pointer;
                }
                .second{
                    color: #2F509E;
                    padding: 10px 15px;
                    border-radius: 20px;
                    text-align: center;
                    border: 1px solid #BBBBBB;
                    margin-left: 25px;
                    cursor: pointer;
                }
            }
        }
    }
}
/* 列表 */
.list_content_box{
    margin-top: 30px;
    background: #fff;
    .list_content{
        .list{
            padding: 50px 0;
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                padding: 0 30px;
            }
            .line{
                width: 100%;
                height: 2px;
                background: rgba(187, 187, 187, 100);
                margin-top: 40px;
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 30px;
                line-height: 24px;
                padding: 0 30px;
            }
        }
    }
}
.bottom_line_box{
    padding: 50px 0;
    background: #fff;
    .bottom_line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
    }
}
</style>